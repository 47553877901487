import React from 'react';
// const Signin2 = React.lazy(() => import('./Cevra/Authentication/SignIn/Login'));
// const registration = React.lazy(() => import('./Cevra/Authentication/SignIn/Registartion'));
const signUp = React.lazy(() => import('./Cevra/Authentication/signUp/signUpMain'));
// const forgot = React.lazy(() => import('./Cevra/Authentication/SignIn/Forgot'));
const authIndex = React.lazy(() => import('./Cevra/Authentication/AuthIndex'));

const route = [
    { path: '/auth/login', exact: true, name: 'Login', component: authIndex },
    { path: '/auth/register', exact: true, name: 'Registration', component: authIndex },
    { path: '/auth/sign-up/:name', exact: true, name: 'Sign-up', component: signUp },
    { path: '/auth/forgot', exact: true, name: 'Forgot', component: authIndex },
    // { path: '/auth/authIndex', exact: true, name: 'authIndex', component: authIndex }
];

export default route;